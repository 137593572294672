const RegexDef = {
    NUMBER: /^-?\d+((\.?)|(\.\d+))$/,

    INTEGER: /^-?\d+$/,

    limitDigits(digits) {
        return new RegExp('^-?\\d+((\\.?)|(\\.\\d{0,' + digits + '}))$')
    },

    limitLength(length) {
        return new RegExp('^[\\s\\S]{0,' + length + '$');
    }
}

export default RegexDef;