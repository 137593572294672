<template>
  <div class="scheme-list layout-vertical margin-left padding-left-large padding-left">
    <div class="layout-inflexible layout-horizontal layout-middle">
      <div class="font-size-extra-large margin-right">
        <back-button @click="handleBackClick"></back-button>
      </div>
      <ys-input placeholder="搜索干预方案" prefix-icon="el-icon-search" class="margin-vertical input-search" dark hollow
                v-model="search">
      </ys-input>
      <ys-button lighting class="margin-left-large" @click="handleAddClick">+ 添加干预方案</ys-button>
    </div>
    <div class="layout-flexible" style="overflow: hidden">
      <ys-infinite-scroll-view style="height: 100%" @loadmore="loadMore">
        <div class="padding-vertical-small">
          <div>
            <span class="font-size-large font-weight-bold">干预方案</span>
            <span class="font-size-medium margin-left">
              <span class="font-color-secondary">共</span>
              <span class="font-color-primary">{{ totalCount }}</span>
              <span class="font-color-secondary">份干预方案</span>
            </span>
          </div>
          <div class="font-size-medium font-color-secondary margin-top-small margin-bottom">管理干预方案</div>
          <div class="list-item layout-horizontal layout-middle" v-for="(scheme, index) in schemeList" :key="scheme.id"
               @click="clickScheme(index)">
            <div class="layout-inflexible">
              <i class="fas fa-seedling font-size-large"></i>
            </div>
            <div class="margin-horizontal-large layout-flexible" style="overflow: hidden;">
              <div class="font-size-medium">
                <span class="font-weight-bold">
                <span v-if="scheme.commodityState === CommodityState.ON_SHELVES">售卖中:</span>
                <span v-else>已下架:</span>
                </span>
                <span class="margin-left-small">{{ scheme.title }}</span>
              </div>
              <div class="font-size-small font-color-secondary margin-top-small font-nowrap">{{ scheme.message }}</div>
              <div v-if="scheme.originalCostStr" class="font-size-small font-color-danger"
                   style="text-decoration: line-through; font-style: italic">
                原价：￥{{ scheme.originalCostStr }}
              </div>
              <div class="font-size-small font-color-danger">
                <span v-if="scheme.costStr">￥{{ scheme.costStr }}</span>
                <span v-else>免费</span>
              </div>

            </div>
            <div class="layout-inflexible">
              <ys-button class="btn-delete" round type="secondary" @click.stop="handleDeleteClick(scheme)">
                <i class="far fa-trash-alt font-color-danger"></i>
              </ys-button>
            </div>
            <div class="layout-inflexible margin-left font-color-secondary">
              <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <delete-dialog :visible.sync="deleteRemindDialogVisible" message="您确定要删除该干预方案吗？"
                   @confirm="handleDeleteConfirm"></delete-dialog>
  </div>
</template>

<script>
import YsInput from "@/components/wedigets/YsInput";
import httpapi from "@/assets/javascript/httpapi";
import BackButton from "@/components/backbutton/BackButton";
import YsButton from "@/components/wedigets/YsButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import ExactNumber from "@/assets/javascript/exact-number";
import {CommodityState} from "@/assets/javascript/commodity-utils";

function doLoadSchemeList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: this.pageSize,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    for (let scheme of res.data.list) {
      if (scheme.originalCost && ExactNumber.compare(scheme.originalCost, ExactNumber.of(0)) > 0) {
        scheme.originalCostStr = ExactNumber.stringify(scheme.originalCost);
      }
      if (scheme.cost && ExactNumber.compare(scheme.cost, ExactNumber.of(0)) > 0) {
        scheme.costStr = ExactNumber.stringify(scheme.cost);
      }
    }
    let schemeList = this.requireRefresh ? [] : this.schemeList;
    this.$appendAfter(schemeList, res.data.list);
    this.schemeList = schemeList;
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.totalCount = res.data.total;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveScheme(id) {
  if (this.busyLoadingCode === this.loadingCode) {
    this.$message.error('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqDelete({
    path: `/scheme/${id}`,
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    for (let n = this.schemeList.length - 1; n >= 0; n--) {
      if (this.schemeList[n].id === id) {
        this.schemeList.splice(n, 1);
        break;
      }
    }
    this.$message.success('删除成功');
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('删除失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doSearchSchemeList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/search',
    data: {
      organizationId: this.organizationId,
      search: this.search,
      pageNum: pageNum,
      pageSize: this.pageSize,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    let schemeList = this.requireRefresh ? [] : this.schemeList;
    this.$appendAfter(schemeList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "SchemeList",
  mixins: [httpapi],
  components: {DeleteDialog, YsInfiniteScrollView, YsButton, BackButton, YsInput},
  props: {
    organizationId: Number,
  },
  data() {
    return {

      CommodityState: CommodityState,

      /**
       * 搜索条件
       */
      search: null,

      loadingCode: 1,
      busyLoadingCode: 0,

      schemeList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,
      totalCount: 0,
      requireRefresh: false,

      searchingTimeoutId: null,

      currentSchemeId: null,

      deleteRemindDialogVisible: false,
    }
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode++;
        this.requireRefresh = true;
        this.pageNum = 1;
        this.totalPages = 0;
        this.search = null;
        if (this.organizationId) {
          this.loadSchemeList();
        }
      },
      immediate: true,
    },
    search: function () {
      this.loadingCode++;
      this.requireRefresh = true;
      this.pageNum = 1;
      this.totalPages = 0;
      if (this.searchingTimeoutId) {
        clearTimeout(this.searchingTimeoutId);
      }
      if (this.organizationId) {
        this.searchingTimeoutId = setTimeout(() => this.loadSchemeList(), 500);
      }
    },
  },
  activated: function () {
    if (this.organizationId) {
      this.loadSchemeList();
    }
  },
  methods: {
    handleBackClick: function () {
      this.$router.back();
    },
    handleAddClick: function () {
      this.$router.push({name: 'scheme'});
    },
    handleDeleteClick: function (scheme) {
      this.currentSchemeId = scheme.id;
      this.deleteRemindDialogVisible = true;
    },
    handleDeleteConfirm: function () {
      doRemoveScheme.bind(this)(this.currentSchemeId);
    },
    clickScheme: function (index) {
      this.$router.push({name: 'scheme', query: {id: this.schemeList[index].id}})
    },
    loadSchemeList: function (pageNum = 1) {
      if (this.search) {
        doSearchSchemeList.bind(this)(pageNum);
      } else {
        doLoadSchemeList.bind(this)(pageNum);
      }
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) {
        this.loadSchemeList(this.pageNum + 1);
      }
    }
  }
}
</script>

<style scoped>

.scheme-list {
  height: 100vh;
}

.btn-delete {
  visibility: hidden;
}

.list-item:hover .btn-delete {
  visibility: visible;
}
</style>