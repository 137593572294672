export const CommodityState = {

    /**
     * 下架状态
     */
    OFF_SHELVES: 0,

    /**
     * 上架状态
     */
    ON_SHELVES: 1,
}

function defaultCommodity() {
    return {
        cover: null,
        cost: null,
        originalCost: null,
        state: CommodityState.OFF_SHELVES,
        presentationItems: [],
    }
}

const CommodityUtils = {
    CommodityState,
    defaultCommodity,
}

export default CommodityUtils;