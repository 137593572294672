import RegexDef from "@/assets/javascript/regex-definitions";

const stringify = (exactNumber, withUnit = false, trimZero = true) => {
    if(exactNumber == null) return null;
    let result = exactNumber.value + '';
    if(exactNumber.digits > 0) {
        let dotIndex = result.length - exactNumber.digits;
        if(dotIndex > 0) {
            result = result.slice(0, dotIndex) + '.' + result.slice(dotIndex);
        } else {
            let zeroDefinition = '0.00000000000000000';
            result = zeroDefinition.slice(0, 2 - dotIndex) + result;
        }
    }

    if(withUnit && exactNumber.unit) {
        result += exactNumber.unit;
    }

    if(trimZero) {
        let dotIndex = result.length;
        for(let n = 0; n < result.length; n++) {
            if('.' === result[n]) {
                dotIndex = n;
                break;
            }
        }
        let trimCount = 0;
        for(let n = result.length - 1; n >= 0; n--) {
            if(n < dotIndex) break;
            if(result[n] === '0') {
                trimCount ++;
            } else if(result[n] === '.') {
                trimCount ++;
                break;
            } else {
                break;
            }
        }
        result = result.substring(0, result.length - trimCount);
    }
    return result;
}

const toDigits = (exactNumber, digits) => {
    if(exactNumber == null) return null;
    let result = {
        value: exactNumber.value,
        digits: exactNumber.digits,
        unit: exactNumber.unit
    }
    let offset = digits - exactNumber.digits;
    if(offset == 0) {
        return result;
    } else if(offset > 0 && offset <= 10) {
        result.value = parseInt(result.value * EXP[offset]);
        result.digits = digits;
    } else if(offset < 0 && offset >= -10) {
        result.value = parseInt(result.value * DIV[-offset]);
        result.digits = digits;
    } else {
        result.value = parseInt(result.value * Math.pow(10, offset));
        result.digits = digits;
    }
    return result;
}

const DIV = [1,//0
             0.1,//1
             0.01,//2
             0.001,//3
             0.0001,//4
             0.00001,//5
             0.000001,//6
             0.0000001,//7
             0.00000001,//8
             0.000000001,//9
             0.00000000001//10
]

const EXP = [1,//0
             10,//1
             100,//2
             1000,//3
             10000,//4
             100000,//5
             1000000,//6
             10000000,//7
             100000000,//8
             1000000000,//9
             10000000000,//10
]

const toFloat = (exactNumber) => {
    if(exactNumber == null) return null;
    let result = exactNumber.value;
    if(exactNumber.digits == 0) {
        return result;
    } else if(exactNumber.digits > 0 && exactNumber.digits <= 10) {
        return result * DIV[exactNumber.digits];
    } else if(exactNumber.digits < 0 && exactNumber.digits >= -10) {
        return result * EXP[-exactNumber.digits];
    } else {
        return result * Math.pow(0.1, exactNumber.digits);
    }
}

const compare = (exactNumber1, exactNumber2) => {
    if(!exactNumber1) return false;
    if(!exactNumber2) return false;
    let val1 = toFloat(exactNumber1);
    let val2 = toFloat(exactNumber2);
    if(val1 == val2) {
        return 0;
    } else if(val1 > val2) {
        return 1;
    } else {
        return -1;
    }
}

const parse = (str = '', unit = null) => {
    if(str == null) return null;
    if(!RegexDef.NUMBER.test(str)) {
        throw new Error('invalid number format string: ' + str);
    }
    let numberStart = 0;
    for(let n = 0; n < str.length; n++) {
        if(str.charAt(n) != '0') {
            numberStart = n;
            break;
        }
    }
    if(numberStart > 0) {
        str = str.slice(numberStart);
    }
    let dotIndex = str.indexOf('.');
    if(dotIndex >= 0) {
        let digits = str.length - dotIndex - 1;
        let value = parseInt(str.slice(0, dotIndex) + str.slice(dotIndex + 1));
        return {
            digits: digits,
            value: value,
            unit: unit
        }
    } else {
        let digits = 0;
        let value = parseInt(str);
        return {
            digits: digits,
            value: value,
            unit: unit
        }
    }
}

const of = (val, digits = 0, unit = null) => {
    if(val == null || digits == null) {
        throw new Error('val and digits can not be null');
    }
    return {
        value: parseInt(val),
        digits: parseInt(digits),
        unit: unit,
    }
}
const ExactNumber = {
    stringify: stringify,
    toFloat: toFloat,
    toDigits: toDigits,
    compare: compare,
    parse: parse,
    of: of,
}

export default ExactNumber;